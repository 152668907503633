<template>
  <default-page>
    <section-frame title="Past News" subTitle="ニュース一覧" bBack class="mt-6">
      <v-row dense class="justify-center">
        <v-col
          v-for="(article, index) in arrNews"
          :key="index"
          cols="12"
          md="7"
        >
          <home-news-card :article="article"></home-news-card>
        </v-col>
      </v-row>
      <v-btn
        color="white"
        class="ml-2 mt-10"
        rounded
        small
        outlined
        @click="$router.go(-1)"
        >back</v-btn
      >
    </section-frame>
  </default-page>
</template>

<script>
import DefaultPage from '@/components/DefaultPage.vue'
import SectionFrame from '@/components/SectionFrame'
import news from '@/assets/data/News.js'
import HomeNewsArticle from '@/components/HomeNewsAirticle.vue'
import HomeNewsDialog from '@/components/HomeNewsDialog.vue'
import HomeNewsCard from '@/components/HomeNewsCard.vue'
export default {
  components: {
    DefaultPage,
    SectionFrame,
    HomeNewsArticle,
    HomeNewsDialog,
    HomeNewsCard,
  },
  data() {
    return {
      arrNews: news,
    }
  },
}
</script>
