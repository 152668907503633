<template>
  <section>
    <v-dialog v-model="dialog" class="ma-1" max-width="800px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-2 mt-1"
          outlined
          rounded
          small
          v-bind="attrs"
          v-on="on"
        >
          read more
        </v-btn>
      </template>
      <v-card class="grey darken-3 pt-2">
        <home-news-article :article="article"></home-news-article>
        <v-divider dark />
        <v-card-actions class="justify-center">
          <!-- <v-spacer></v-spacer> -->
          <v-btn color="white" @click.stop="dialog = false" outlined>
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import HomeNewsArticle from './HomeNewsAirticle.vue'
export default {
  components: { HomeNewsArticle },
  data() {
    return {
      dialog: false,
    }
  },
  props: {
    article: {
      type: Object,
      required: true,
      default: function () {
        return {}
      },
    },
  },
}
</script>

<style scoped>
.nowrap span {
  white-space: nowrap;
  line-height: 1.7rem;
}
</style>
