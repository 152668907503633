<template>
  <v-card dark class="grey darken-2">
    <div class="d-flex flex-no-wrap justify-left">
      <v-avatar class="ma-1" size="100" tile>
        <v-img :src="article.src"></v-img>
      </v-avatar>
      <div>
        <v-card-subtitle class="text-left my-0 py-0"
          >{{ article.date }} |<b> {{ article.category }}</b></v-card-subtitle
        >
        <v-card-title
          class="text-left py-0"
          style="line-height: 1.3rem; font-size: 1rem; font-weight: bold"
        >
          {{ article.title }}
        </v-card-title>
        <v-card-actions>
          <!-- <router-link :to="'/update/' + article.title">news link</router-link> -->
          <!-- <home-news-dialog :article="article" /> -->
          <v-btn
            class="ml-2 mt-1"
            rounded
            small
            outlined
            :to="'/news/' + article.id"
            target="_self"
          >
            <span style="font-size: 0.8rem">read more</span>
          </v-btn>
        </v-card-actions>
      </div>
    </div>
  </v-card>
</template>

<script>
// import HomeNewsDialog from '@/components/HomeNewsDialog.vue'

export default {
  // components: { HomeNewsDialog },
  props: {
    article: {
      type: Object,
      require: true,
    },
  },
  // methods: {
  //   btnClick(index) {
  //     console.log(index, this.arrShow)
  //     if (this.arrShow.includes(index)) {
  //       const id = this.arrShow.findIndex((el) => el === index)
  //       this.arrShow.splice(id, 1)
  //     } else {
  //       this.arrShow.push(index)
  //     }
  //   },
  // },
}
</script>

